<script setup>

import '@aws-amplify/ui-vue/styles.css'
import 'swagger-ui-dist/swagger-ui.css'
import {SwaggerUIBundle, SwaggerUIStandalonePreset} from "swagger-ui-dist"
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-vue";
import {onUpdated} from "vue";


const auth = useAuthenticator();

const loadSwaggerUI = () => {
  if (auth.authStatus !== 'authenticated') {
    return
  }
  SwaggerUIBundle({
    urls: [
      {url: "./v001/ActivityApi.yaml", name: "ActivityApi"},
      {url: "./v001/CompanyApi.yaml", name: "CompanyApi"},
      {url: "./v001/EmployeeApi.yaml", name: "EmployeeApi"},
      {url: "./v001/PersonApi.yaml", name: "PersonApi"},
      {url: "./v001/RealEstateApi.yaml", name: "RealEstateApi"},
      {url: "./v001/SearchRegionApi.yaml", name: "SearchRegionApi"}],
    validatorUrl: 'none',
    dom_id: '#swagger-ui',
    deepLinking: true,
    presets: [
      SwaggerUIBundle.presets.apis,
      SwaggerUIStandalonePreset,
    ],
    layout: "StandaloneLayout",
    tryItOutEnabled: false
  });
}

onUpdated(() => loadSwaggerUI())
</script>

<template>
  <authenticator :login-mechanisms="['email']" :hide-sign-up="true"></authenticator>
  <template v-if="auth.authStatus === 'authenticated'">
    <button @click="auth.signOut">Logout</button>
    <div class="swagger" id="swagger-ui"></div>
  </template>
</template>
