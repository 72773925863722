import {createApp} from 'vue';
import App from './App.vue';
import AmplifyVue from '@aws-amplify/ui-vue';
import {Amplify} from "aws-amplify";


Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_KzfeCrc9G',
    userPoolWebClientId: '5gmah3e4kt67unhvplekhpgui4',
  }
});


const app = createApp(App);
app.use(AmplifyVue);
app.mount('#app');
